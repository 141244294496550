//BOOTSTRAP OVERRIDES

//navbar icon alignment issue
.navbar-toggler.mat-icon-button {

  span {
    bottom: auto !important;
    top: 4px;
    left: -1px;
  }

}
