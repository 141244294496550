$pdfcontrols-bg:      #f6f6f6;
$pdfcontrols-bghover: #e6e6e6;
$pdfcontrols-border:  #d7d7d7;
$pdfcontrols-text:    #595959;


.pdf-viewer-file-name{
  margin: 15px !important;
}

.pdf-viewer-loading{
  width: 100%;
  margin: 15px !important;
}

.o-pdfcontrols {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;

}

  .pdfcontrols__zoom {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    width: 40px;
    height: 36px;
    box-sizing: border-box;
    background-color: $pdfcontrols-bg;
    border: 1px solid $pdfcontrols-border;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 50px;

    &--zoomin {
      margin-right: 2px;
      border-radius: 5px 0 0 5px;
    }

    &--zoomout {
      border-radius: 0 5px 5px 0;
    }

    &:hover {
      background-color: $pdfcontrols-bghover;
    }

  }

  .pdfcontrols__open {
    position: absolute;
    right: 10px;
    display: inline-block;
    padding: 0 50px 0 10px;
    height: 36px;
    line-height: 36px;
    color: $pdfcontrols-text;
    font-size: 11px;
    background-color: $pdfcontrols-bg;
    border: 1px solid $pdfcontrols-border;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;

    &:hover {
      background-color: $pdfcontrols-bghover;
    }

  }


  .pdfcontrols__icon {
    position: absolute;
    top: 0;
    width: 40px;
    height: 36px;
    background-size: 22px;
    background-position: center center;
    background-repeat: no-repeat;

    &--zoomin {
      left: 0;
      background-image: url('/assets/img/pdfcontrols/ico_zoomin.svg');
    }

    &--zoomout {
      left: 0;
      background-image: url('/assets/img/pdfcontrols/ico_zoomout.svg');
    }

    &--open {
      right: 0;
      background-image: url('/assets/img/pdfcontrols/ico_popout.svg');
      border-left: 1px solid $pdfcontrols-border;
    }

  }

