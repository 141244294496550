/* Variables */

$font-family-main: 'Roboto',
sans-serif;
$font-family-main-semibold: 'Roboto',
sans-serif;
$font-icons-bootstrap:"Glyphicons Halflings",
sans-serif;
$font-icons-material:"Material Icons";
$font-color:#546e7a;
$font-color-navbar:#546e7a;
$font-color-p:#546e7a;
$title-color:#263238;
$color-primary: #42a5f5;
$accent: #42a5f5;
$warn: #42a5f5;
$color-accent: #42a5f5;
$foreground-base:$accent;
$primary: $color-primary;
$ripple-checked-color: $color-primary;
$color-primary-dark: #36a0f7;
$color-primary-hover: #36a0f7;
$color-btn: #fff;
$color-light: #fff;
$bgr-header: #fff;
$bgr-body: #f1f3f5;
$font-size: 14px;
$p-font-size: 14px;
$color-info: #64b5f6;
$md-radio-border-color: #d5dbde;
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15px;
$edit-btn-color: #8e9ca4;
$color-button-hover: #48647C;
/*$details-color: #90a4ae;
$label-color: #90a4ae;
$label-span-color: #90a4ae;*/

$details-color: #7695a5;
$label-color: #7695a5;
$label-span-color: #7695a5;
$border-btn: #c1ccd1;
$row-odd-color: #fafbfc;
$head-color: #fafbfc;
$cancel-btn-color: #b0bec5;
$disabled: #b0bec5;
$placeholder-color: #00000073;
$switch-off:#d7e0e4;
$border-color: #eceff1;
$active-nav-link-line: #cfd8dc;
$actions-color: #607d8b;
$tab-color: #f0f3f5;
$tab-color-selected: #c1ccd1;
$color-error: #e87366;
$color-del: #ef5350;
$bgr-error: #fff7f7;
$color-sucess: #81c884;
$color-sucess1: #3acba0;
$color-warning: #ffd54f;
$color-warning1: #fffaec;
$border-row: #dfdfdf;
$status-accepted: #2AD314;
$status-pending: #FCDF06;
$status-canceled: #5a6268;
$status-completed: #DBDADA;
$status-missed: #FF3B30;
$color-card-hover: #960000;
$color-main-green: #009688;
$light-grey: #DFE5E9;
