$font-size: 14px;
$font-size-m: 12px;
$height: 75px;

.container {
  padding: 0 20px;

  a:hover {
    text-decoration: none;
  }

}

.mat-menu-item:hover {
  text-decoration: none;
}

nav.navbar .container-fluid {
  padding: 0 20px;
}

nav.navbar .brand img.logo-symbol {
  height: $height;
  padding: 10px 10px 10px 0;
}

nav.navbar .wrap-navbar-left .nav-link {
  padding: 0;
}

nav.navbar .brand {
  //display: flex;
}

nav.navbar .navbar-brand {
  //display: flex;
}

.clinic-details {
  //padding: 5px;
  font-size: $font-size;
  color: #546e7a;
  //height: $height;
  //display: table;

  .clinic-name {
    font-size: 18px;
    color: #263238;
  }

  .clinic-first {
    //display: table-cell;
    //vertical-align: bottom;
  }

  .clinic-phone,
  .clinic-web,
  .clinic-email {
    a {
      display: block;
      padding-left: 35px;
      position: relative;
      //font-size: $font-size;
      font-size: 14px;
      margin: 0 20px;
      line-height: 14px;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      span {
        display: block;
        color: #555;
        bottom: 0;
        font-size: 11px;
      }

      .material-icons {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -14px;
        font-size: 28px;
        line-height: 28px;
        color: #555;
      }

    }
  }

  .clinic-web,
  .clinic-email {
    //display: table-cell;
    //vertical-align: bottom;
  }


  .material-icons {
    top: 3px;
    position: relative;
    font-size: 16px;
    line-height: 0;
  }
}

.clinic-details-mobile {
  padding-top: 5px;
  font-size: $font-size-m;
  color: #546e7a;
  //height: $height;

  .clinic-name {
    font-size: 14px;
    color: #263238;
  }

  .clinic-phone,
  .clinic-web,
  .clinic-email {
    line-height: 1.45;

    a {
      //font-size: $font-size-m;
    }
  }

  .material-icons {
    top: 3px;
    position: relative;
    font-size: 14px;
    line-height: 0;
  }
}

@media only screen and (min-width : 768px) and (max-width : 1200px) and (-webkit-min-device-pixel-ratio: 1) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

}

@media (min-width: 1200px) {
  // .clinic-details {
  //   margin-right: 50px;
  // }
}

@media (min-width: 768px) {
  // .clinic-details {
  //   margin-right: 25px;
  //   display: table;
  // }

  .clinic-details-mobile {
    display: none;
  }
}


@media (max-width: 767px) {
  .clinic-details {
    display: none;
  }

  .clinic-details-mobile {
    display: flex;

    a {
      font-size: 0;
    }

    .material-icons {
      font-size: 24px;
      margin-right: 20px;
    }

  }

  .container {
    padding: 0;
  }

  .navbar-brand {
    width: 100%;
  }

}

.top-title.top-title-app {
  margin-bottom: 0;
}


//NEW HEADER LAYOUT
nav.navbar {

  .brand,
  .navbar-brand {
    float: none;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
    //margin: 0 20px;
  }

  .navbar-collapse {
    flex-grow: 0;
    flex-basis: 0;
  }

  .logo {
    display: block;
  }

  .nav-link {
    padding: 0 40px;
    line-height: 34px;
    text-transform: uppercase;
    color: #fff;
    background-color: $color-primary;
    border-radius: 3px;

    &:hover {
      color: #fff;
      //background-color: $color-primary-hover;
      background-color: #0056b3;
    }

  }

  .desktop-only .nav-link {
    color: #555;
    background-color: unset;
    text-transform: none;
    padding: 0 10px;

    &:hover {
      color: #0056b3;
      background-color: rgba(0, 0, 0, .04);
    }
  }

  .navbar-toggler {

    .material-icons {
      color: $color-primary;
    }

    &:hover {

      .material-icons {
        //color: $color-primary-hover;
        color: #0056b3;
      }

    }

  }

}
