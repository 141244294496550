@keyframes pulse {
  0% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

.placeholder {
  padding: 15px 15px;
  margin-bottom: 10px;
}

.line {
  height: 10px;
  margin: 10px 0;
}

.circle,
.line,
.rect {
  background: #e0e0e0;
  -webkit-animation: pulse .65s infinite alternate;
  animation: pulse .65s infinite alternate;
}

.circle {
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  width: 56px;
  height: 56px;
}

.line-wrapper {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.width-100 {
  width: 100%;
}

.width-90 {
  width: 90%;
}

.width-80 {
  width: 80%;
}

.width-70 {
  width: 70%;
}

.width-60 {
  width: 60%;
}

.width-50 {
  width: 50%;
}

.width-30 {
  width: 30%;
}

.width-50px {
  width: 50px;
}

.height-20px {
  height: 20px;
}

.height-30px {
  height: 30px;
}