// // Reset and dependencies
@import "bootstrap";
@import "bootstrap-override";
@import "normalize";
@import "font-awesome";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

// Core variables and mixins
@import "variables";

// Core CSS
@import "toastr";
@import "loader";
@import "main";

@import "onboarding-forms";
@import "top-navbar";

//Forms PDF controls
@import "pdfcontrols";


//Angular
@import "_material";
@import "material-theme/material-theme";

@import "~stream-chat-angular/src/assets/styles/scss/index.scss";
@import "~@unifiedpractice/up-chat-lib/styles.css";

html {
  height: initial;
}
