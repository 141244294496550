@media screen {
  @mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
  }

  /* ==========================================================================
	   Base styles
	   ========================================================================== */
  html {
    background-color: $color-light;
    color: $font-color;
    font-size: 1em;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
  }

  * {
    outline: 0;

    &:focus {
      outline: 0;
    }
  }

  img,
  svg,
  video {
    vertical-align: middle;
  }

  /* ==========================================================================
	   Helper classes
	   ========================================================================== */

  .not-editable-form {
    pointer-events: none !important;
  }

  .col-no-padding,
  .form-row > .col-no-padding {
    padding-right: 0;
    padding-left: 0;
  }

  .hidden {
    display: none !important;
  }

  .invisible {
    visibility: hidden;
  }

  .clearfix:before,
  .clearfix:after {
    content: ' ';
    /* 1 */
    display: table;
    /* 2 */
  }

  .clearfix:after {
    clear: both;
  }

  iframe {
    border: 0;
  }

  textarea {
    resize: none;
    outline: none;
  }

  .no-padding {
    padding: 0;
  }

  .no-padding-left-right {
    padding-left: 0;
    padding-right: 0;
  }

  .form-group {
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }

    input,
    textarea {
      font-size: 14px;

      &:focus,
      &:active {
        outline: none;
      }
    }
  }

  .col-no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .text-center {
    text-align: center;
  }

  /* ==========================================================================
	   Custom styles
	   ========================================================================== */
  html {
    background-color: $color-light;
    margin: 0;
    padding: 0;
    width: 100%;
    font-weight: normal;
    font-style: normal;
    color: $color-light;
    font-family: $font-family-main;
  }

  p {
    font-size: $p-font-size;
    line-height: 21px;
    color: $font-color;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    font-size: $font-size;
    color: $color-primary;

    span {
      position: relative;
      bottom: 5px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-main;
    font-weight: normal;
  }

  .center {
    text-align: center;
  }

  body {
    font-family: $font-family-main;
    color: $font-color;
    font-size: 14px;
    font-weight: normal;
    height: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    width: 100%;
    background: $bgr-body;
    position: relative;
    float: left;
    box-sizing: border-box;

    .page {
      width: 100%;
    }

    div {
      box-sizing: border-box;
    }

    input::placeholder,
    input::placeholder {
      color: $placeholder-color;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $placeholder-color;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $placeholder-color;
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: $placeholder-color;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: $placeholder-color;
    }
  }

  .form-control::-webkit-input-placeholder {
    color: $placeholder-color;
    opacity: 1;
  }

  .form-control::-moz-placeholder {
    color: $placeholder-color;
    opacity: 1;
  }

  .form-control:-ms-input-placeholder {
    color: $placeholder-color;
    opacity: 1;
  }

  .form-control:-ms-input-placeholder {
    color: $placeholder-color;
    opacity: 1;
  }

  .form-control::placeholder {
    color: $placeholder-color;
    opacity: 1;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  section {
    padding: 50px 0;
  }

  .container-fluid {
    padding: 0 20px;
  }

  .no-margin {
    margin: 0;
  }

  /* ==========================================================================
		Top page - Header
	========================================================================== */
  header {
    width: 100%;
    float: left;
    -webkit-box-shadow: inset 0 0 0 rgba(255, 255, 255, 0), 0 0 0 rgba(0, 0, 0, 0.001);
    box-shadow: inset 0 0 0 rgba(255, 255, 255, 0), 0 0 0 rgba(0, 0, 0, 0.001);
    padding: 0;
  }

  /* ==========================================================================
		Main section
	========================================================================== */
  /*=== Helpers ===*/
  .page {
    overflow-x: hidden;
  }

  .no-padding {
    padding: 0 !important;
  }

  /*
	* ==========================================================
	*     MAIN NAVBAR
	* ==========================================================
	*/
  nav {
    &.navbar {
      margin: 0 auto;
      background-color: $bgr-header;
      @include border-radius(0);
      border: 0;
      padding: 0 50px;
      height: 75px;
      color: $color-light;
      position: relative;
      z-index: 99;
      padding-left: 0;
      padding-right: 0;
      text-align: left;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      box-shadow: 0 0 5px 0 rgba(6, 9, 55, 0.4);

      .navbar-container {
        padding: 0 35px;

        .navbar-header {
          width: 100%;

          .wrap-navbar-right {
            //float: right;
            text-align: right;
          }
        }
      }

      .brand {
        float: left;
        height: 100%;
        padding: 0;

        img {
          padding: 0 10px 0 0;

          &.logo-symbol {
            height: 38px;
          }

          &.logo-text {
            height: 10px;
          }
        }
      }

      .navbar-brand {
        float: left;
        height: 100%;
        padding: 0;
      }

      .container-fluid {
        width: 100%;
      }

      .nav-link {
        //height: 75px;
        //float: left;
        //display: flex;
        //justify-content: flex-start;
        //align-items: center;
        display: inline-block;
        font-size: 14px;
        line-height: 16px;
        padding: 0 0 0 35px;
        color: $font-color;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  .menu-btn {
    margin: 0;
    transition: all 0.8s;
    float: left;
    padding: 5px;
    height: 40px;

    i {
      color: $font-color;
      width: 20px;
      height: 2px;
      display: block;
      margin: 4px auto 0;
      transition: all 0.3s cubic-bezier(0.81, -0.33, 0.345, 1.375);
      position: relative;
    }

    &.active {
      i {
        transform: rotateY(180deg);
      }
    }
  }

  .navbar-collapse.show {
    display: block;
    position: absolute;
    background: #fff;
    width: 100%;
    top: 75px;
    left: 0;

    .wrap-navbar-right {
      float: left;
      width: 100%;

      a {
        float: left;
        width: 100%;
      }
    }
  }

  /* content */
  .container {
    width: 1240px;
    padding: 20px 0;
  }

  .content-section-extraspace {
    padding: 100px 0px;
  }

  .content-inner,
  .content-inner-deprecated {
    .wrap-section {
      margin-top: 20px;

      &.wrap-section-appointment-loggedin {
        margin-top: 5px;
      }

      &.wrap-section-first {
        margin-top: 15px;
      }
    }

    .box {
      background: $color-light;
      box-shadow: 0 2px 2.7px 0.3px rgba(25, 25, 25, 0.03);
      padding: 30px;
      min-height: 100px;
      border: 0;
      border-radius: 0;
      margin-bottom: 40px;

      &.alert,
      &.message {
        padding: 20px 30px;
        margin: 0;
        color: $font-color;
        font-size: 14px;
        line-height: 26px;
        box-shadow: 0 0 0 0 rgba(5, 26, 56, 0.35);
        border-left: 4px solid $color-primary;
        min-height: 66px;

        &.alert-warning {
          border-left: 4px solid $color-warning;

          i {
            color: $color-warning;
          }
        }

        &.message-warning {
          background-color: $color-warning1;

          i {
            color: $color-warning;
          }
        }

        &.alert-danger {
          border-left: 4px solid $color-error;

          i {
            color: $color-error;
          }
        }

        &.message-danger {
          i {
            color: $color-error;
          }
        }
      }

      &.message {
        border-left: 0;
        padding-left: 15px;
      }

      &.login-box {
        margin-top: 100px;
      }
    }
  }

  .wrap-title {
    width: 100%;
    padding: 0 15px;
  }

  .title {
    font-family: $font-family-main;
    line-height: 36px;
    color: $title-color;
    margin: 10px 0 20px 0;
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    text-align: left;

    &.title-top-success {
      font-size: 20px;
      font-weight: normal;
      line-height: 1.5;
      text-align: center;
      margin: 10px 0 5px 0;
    }
  }

  .top-title {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: normal;
    text-align: left;
    color: $title-color;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .subtitle {
    font-size: 17px;
    font-family: $font-family-main;
    line-height: 30px;
    color: $font-color;
  }

  .top-subtitle {
    font-size: 16px;
    font-family: $font-family-main;
    line-height: 30px;
    color: $title-color;
    margin: 0;
  }

  .col-right {
    p {
      font-size: 14px;
      color: $font-color-p;
    }
  }

  .link-forgot {
    padding-left: 10px;
  }

  .form-group {
    position: relative;
  }

  .form-row {
    /*padding: 10px 15px 0 10px;*/
    padding: 10px 20px 10px 20px;
    margin-left: 0;
    margin-right: 0; // &.calendar-row {

    //     padding: 10px 10px 0 10px;
    // }
    &--upload {
      flex-direction: column;
    }
  }

  .form-control,
  .form-group .form-control {
    border: 0;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
  }

  .form-control:disabled,
  .form-control[readonly] {
    color: $disabled;
    background-color: transparent;
  }

  .form-group .form-control:focus {
    outline: none;
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    transition-duration: 0.3s;
  }

  input,
  .form-control {
    font-family: $font-family-main;
    color: $font-color;
    font-style: normal;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    border: 0;
    background-image: linear-gradient($color-primary, $color-primary), linear-gradient(#d2d2d2, #d2d2d2);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: transparent;
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 12px;
    padding: 0;

    &:focus {
      outline: none;
      background-size: 100% 2px, 100% 1px;
      background-image: linear-gradient($color-primary, $color-primary), linear-gradient(#d2d2d2, #d2d2d2);
      box-shadow: none;
      transition-duration: 0.3s;
    }

    &.form-control-select {
      //min-width: 115px;
      min-width: 78px;
      margin-bottom: 5px;
      padding: 0; //background-size: 0 2px, 98% 1px;
      margin-left: -1%;
      margin-right: -1%;
    }
  }

  textarea {
    &.form-control {
      height: 50px;
    }

    &.form-control-lg {
      height: 75px;
    }
  }

  .label-form-control {
    font-size: 14px;
    font-family: $font-family-main;
    font-weight: 500;
    clear: both;
    width: 100%;

    &.required-field-label {
      &:after {
        content: '*';
        color: #e57373;
      }
    }

    &.label-form-control-inline {
      width: auto;
    }
  }

  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    }

    50% {
      box-shadow: 0 0 0 $md-radio-ripple-size rgba(0, 0, 0, 0.1);
    }

    100% {
      box-shadow: 0 0 0 $md-radio-ripple-size rgba(0, 0, 0, 0);
    }
  }

  .md-radio,
  .md-checkbox {
    margin: 22px 0;

    &.md-checkbox-reminder {
      margin: 5px 0 35px 0;

      .label-form-control {
        line-height: 24px;
      }
    }

    &.md-radio-inline,
    &.md-checkbox-inline {
      display: inline-block;
    }

    input[type='radio'],
    input[type='checkbox'] {
      display: none;

      &:checked + label:before {
        border-color: $color-primary;
        animation: ripple 0.2s linear forwards;
      }

      &:checked + label:after {
        /*transform: scale(1);*/
        background: $color-primary;
      }
    }

    label {
      display: inline-block;
      //max-height: 60px;
      position: relative;
      padding: 0 0 0 30px;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
        content: '';
        border-radius: 50%;
        transition: all 0.3s ease;
        transition-property: transform, border-color;
      }

      &:before {
        left: 0;
        top: 2px;
        width: $md-radio-size;
        height: $md-radio-size;
        border: 2px solid $md-radio-border-color;
      }

      &:after {
        top: 7px;
        left: 5px;
        width: $md-radio-checked-size;
        height: $md-radio-checked-size;
        /*transform: scale(0);
				background: $color-primary;*/
        background: transparent;
      }

      span {
        &.small-text {
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          margin: 5px 0 2px 0;
          color: $label-span-color;
        }
      }

      strong {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: normal;
        text-align: left;
        color: $font-color;
        word-wrap: break-word;
      }
    }
  }

  .md-checkbox {
    label {
      display: inline-block;
      max-height: 60px;
      position: relative;
      padding: 0 30px;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;

      &:before,
      &:after {
        border-radius: 0;
      }

      &:before {
        left: 0;
        top: 2px;
      }

      &:after {
        top: 7px;
        left: 5px;
      }
    }
  }

  .content-inner .select-box {
    padding: 20px 15px 30px 15px;

    &.box-appointment-summary {
      padding-bottom: 0;

      &.box-appointment-summary-upcoming-app {
        padding-bottom: 30px;
      }
    }

    .row-md-radio {
      &:nth-child(2n) {
        background-color: $row-odd-color;
        margin-right: -30px;
        margin-left: -30px;
        padding: 0 15px;
      }
    }

    .md-radio-with-image {
      margin: 17px 0;

      label {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0;
        position: relative;

        &:before {
          top: calc(50% - 10px);
        }

        &:after {
          top: calc(50% - 5px);
        }

        .avatar {
          margin: 0 20px;
          float: left;
        }

        span {
          width: calc(100% - 100px);
        }
      }
    }
  }

  .btn-login {
    margin: 2px 0;
    width: 100% !important;
    max-width: 180px !important;
  }

  .details-link {
    span {
      width: 53px;
      height: 12px;
      font-size: 12px;
      line-height: 14px;
      float: left;
      font-weight: normal;
      text-align: left;
      color: $label-span-color;
    }

    i {
      line-height: 14px;
      float: left;
    }

    &.details-time {
      i {
        font-size: 20px;
        padding: 0 3px;
      }
    }
  }

  .content-inner .select-clinic-box {
    .md-radio {
      margin: 20px 0 0 0;

      label {
        padding: 0 35px;
        word-wrap: break-word;
        float: left;
        width: 100%;
      }
    }

    .details-link {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      cursor: pointer;
    }
  }

  button {
    &:hover,
    &:focus,
    &:active {
      outline: 0;
    }
  }

  .btn {
    min-width: 96px;
    height: 44px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 3px;

    &:hover,
    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &.btn-login-large {
      width: 500px;
      max-width: 100%;
    }

    &.btn-small {
      min-width: 80px;
      height: 34px;
    }

    &.btn-left {
      margin-right: 10px;
      float: left;
    }
  }

  .btn-primary {
    background-color: $color-primary;
    border: 2px solid $color-primary;
    border-color: $color-primary;
    box-shadow: 0 2px 2.7px 0.3px rgba(25, 25, 25, 0.03);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    letter-spacing: normal;
    color: $color-btn;

    &:hover,
    &:focus {
      background-color: $color-primary-dark;
      border-color: $color-primary-dark;
      outline: 0;
    }
  }

  .btn-cancel {
    color: $cancel-btn-color;
    box-shadow: 0 2px 2.7px 0.3px rgba(25, 25, 25, 0.03);
    border: solid 2px $cancel-btn-color;
  }

  .btn-cancel1 {
    height: 44px;
    border-radius: 3px;
    color: #fff;
    box-shadow: 0 2px 2.7px 0.3px rgba(25, 25, 25, 0.03);
    background: $cancel-btn-color;
    border: solid 2px $cancel-btn-color;

    &:hover,
    &:focus {
      color: #fff;
      background: $cancel-btn-color;
      // border: solid 2px $cancel-btn-color;
      outline: 0;
    }
  }

  .btn-delete {
    height: 44px;
    border-radius: 3px;
    box-shadow: 0 2px 2.7px 0.3px rgba(25, 25, 25, 0.03);
    background: $color-del;
  }

  .btn-cancel-top {
    position: absolute;
    top: 20px;
    right: 45px;
  }

  .wrap-icon-alert {
    height: 100%;
    float: left;
    padding: 2px 10px 0 0;
    margin-left: -10px;
  }

  .info-icon {
    width: 22px;
    height: 22px;
    float: left;
    font-size: 24px;
    font-weight: normal;
    letter-spacing: normal;
    text-align: left;
    color: $color-info;
  }

  .btn-ghost {
    height: 44px;
    border-radius: 3px;
    box-shadow: 0 2px 2.7px 0.3px rgba(25, 25, 25, 0.03);
    border: solid 2px $border-btn;
    font-family: $font-family-main;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    color: $font-color;
    padding: 0 20px;

    &:hover,
    &:focus {
      background-color: rgba($border-btn, 0.2);
      outline: 0;
    }
  }

  .wrap-buttons-appointment {
    margin: 55px 0;
  }

  .wrap-btn-appointment {
    text-align: center;
    margin: 10px 0 0 0;

    .btn-appointment {
      margin: 10px auto 0;
    }
  }

  .wrap-success-icon {
    padding: 35px 0 40px 0;
  }

  .row-md-radio {
    label {
      span {
        width: 100%;
        float: left;
      }
    }
  }

  .icon-map {
    font-family: $font-icons-material;
    float: left;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: $color-primary;
  }

  .avatar {
    width: 56px;
    height: 56px;
    background-color: #cfd8dc;
    border-radius: 50%;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
      max-width: 135%;
      border-radius: 50%;
    }

    &:hover,
    &:focus {
      border-radius: 50%;
      overflow: hidden;
    }

    &.avatar-small {
      width: 32px;
      height: 32px;
      margin: 0 10px;
    }
  }

  .label-with-avatar {
    display: flex;
    align-items: center;
    width: auto;
    float: left;
  }

  .row-edit {
    border-bottom: 1px solid $border-color;
    margin-right: -30px;
    margin-left: -30px;

    &:last-child {
      border-bottom: 0;
    }

    &.row-edit-actions {
      .edit-col {
        background-color: #fafbfc;
        padding: 20px 30px;
      }

      span,
      i {
        float: left;
        line-height: 20px;
      }

      i {
        margin-right: 10px;
      }
    }
  }

  .row-error {
    /*background-color:rgba($color-error, 0.1);*/
    background-color: $bgr-error;

    i {
      color: $color-error;
    }

    input {
      &:focus {
        background-color: $bgr-error;
        background-image: linear-gradient($color-error, $color-error), linear-gradient(#d2d2d2, #d2d2d2);
      }
    }
  }

  p.error-message-field {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: normal;
    text-align: left;
    color: $color-error;
    margin: -8px 0 0 0;

    .form-row & {
      flex: 0 100%;
    }
  }

  .edit-col {
    /*display: flex;
        justify-content: space-between;*/
    // cursor: pointer;
    display: block;
    padding: 15px 15px 5px 15px;

    .edit-btn {
      position: absolute;
      right: 15px;

      &.check-btn {
        color: $color-sucess;
      }
    }

    label {
      font-size: 14px;
      font-weight: normal;
      text-align: left;
      color: $label-color;
    }

    input {
      cursor: pointer !important;
      cursor: pointer;
      font-size: 14px;
      font-weight: normal;
      text-align: left;
      color: $font-color;
      background-image: none;
      width: 290px;
      max-width: calc(100% - 50px);
      text-overflow: ellipsis;
    }

    .label-with-avatar + input {
      width: 180px;
      max-width: calc(100% - 155px);
    }
  }

  .text-note {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    line-height: 14px;
    color: $details-color;
  }

  .subtitle-create-account {
    margin-bottom: 0;
  }

  .subtitle-primary-insurance {
    margin-bottom: 25px;
  }

  .form-full-width {
    .label-form-control {
      margin-bottom: 3px;
    }

    .form-control {
      line-height: 33px;
    }
  }

  .wrap-multiple-form-controls {
    padding-top: 0;

    /*margin-right: -10px;
		margin-left: -10px;*/
    > .col-lg-12 {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .label-terms {
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    color: $font-color;
  }

  .switch-input {
    display: none;
  }

  .switch-label {
    position: relative; // display: inline-block;
    min-width: 35px;
    cursor: pointer;
    font-weight: 500;
    text-align: left;
    margin: 0;
    padding: 0;
    float: right;
  }

  .switch-label:before,
  .switch-label:after {
    content: '';
    position: absolute;
    margin: 0;
    outline: 0;
    top: 50%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .switch-label:before {
    left: 1px;
    width: 38px;
    height: 22px;
    background-color: $switch-off;
    border-radius: 13px;
  }

  .switch-label:after {
    left: 3px;
    width: 18px;
    height: 18px;
    background-color: #fafafa;
    border-radius: 50%;
    box-shadow: 0 2px 1px -2px rgba(0, 0, 0, 0.14), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 5px 0 rgba(0, 0, 0, 0.04);
  }

  .switch-label .toggle-on {
    display: none;
  }

  .switch-label .toggle-off {
    display: inline-block;
  }

  .switch-input:checked + .switch-label:before {
    background-color: $color-sucess;
  }

  .switch-input:checked + .switch-label:after {
    background-color: $color-light;
    -ms-transform: translate(80%, -50%);
    -webkit-transform: translate(80%, -50%);
    transform: translate(80%, -50%);
  }

  .switch-input[disabled]:checked + .switch-label:before {
    // background-color: $disabled;
    cursor: not-allowed;
  }

  .switch-input[disabled]:checked + .switch-label:after {
    background-color: $color-light;
    cursor: not-allowed;
    -ms-transform: translate(80%, -50%);
    -webkit-transform: translate(80%, -50%);
    transform: translate(80%, -50%);
  }

  .switch-input:checked+.switch-label .toggle-on {
    display: inline-block;
  }

  .switch-input:checked+.switch-label .toggle-off {
    display: none;
  }

  .wrap-switch-row {
    margin-left: -5px;
    margin-right: -5px;
    padding: 25px 20px 25px 20px;

    .switch-text-label {
      font-size: 14px;
      text-align: left;
      color: $font-color;
    }

    .md-switch {
      float: right;
    }
  }

  .note {
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    color: $details-color;
    margin: 13px 0;
  }

  .head-calendar {
    height: 56px;
    background-color: $head-color;
    display: flex;
    justify-content: space-between;
    padding: 18px 20px;
    margin: 0 0 8px 0;

    .head-selected-week {
      float: left;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      text-align: left;
      color: $font-color;
    }

    .wrap-navigation {
      float: right;
      width: 254px;

      .nav-link {
        float: left;
        padding: 0;
        color: $font-color;
        cursor: pointer;

        i {
          font-size: 18px;
          line-height: 20px;
          float: left;
          padding: 0 5px;
        }

        span {
          float: left;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          text-align: left;
          line-height: 32px;
        }

        i {
          color: $color-primary;
        }

        &.next {
          padding: 0 0 0 40px;
        }

        &.disabled {
          color: $disabled;

          i {
            color: $disabled;
          }
        }
      }
    }
  }

  .calendar-row {
    &:last-child {
      margin-bottom: 50px;
    }
  }

  .cal-row-date {
    font-size: 14px;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $font-color;
    width: 100%;
    float: left;
    margin: 6px 0 0 0;
  }

  .radio-tab-more {
    display: flex;
    justify-content: center;
    align-items: center;

    .mat-select-value {
      max-width: 100%;
    }
  }

  .md-radio-tab {
    input[type='radio'],
    input[type='checkbox'] {
      display: none;

      &:checked + label {
        background-color: $tab-color-selected;
      }
    }

    label {
      width: 70px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid $tab-color;
      background-color: $tab-color;
      margin: 15px 8px 0 0;
      display: inline-block;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      text-align: center;

      span {
        padding: 10px 0;
        text-align: center;
        margin: 0 auto;
        float: left;
        width: 100%;
      }
    }

    &:hover {
      label {
        border-color: $color-primary;
        color: $color-primary;
        background-color: #fff;
      }
    }

    &:active {
      label {
        border-color: $color-primary;
        color: #fff;
        background-color: $color-primary;
      }
    }
  }

  .btn-calendar {
    margin: 20px 0 0 0;
  }

  .form-control.form-control-select.calendar-select {
    width: 52px;
    min-width: 52px;
    margin: 10px 0;
    padding: 0;
  }

  .show-grid div {
    background-color: rgba($color-primary, 0.05);
    border: 1px solid $color-primary;
    padding: 5px;
  }

  .section-style-guide {
    padding: 25px 15px;

    &:nth-child(2n) {
      background-color: rgba(66, 165, 245, 0.03);
    }

    &:nth-child(2n + 1) {
      background-color: #fff;
    }
  }

  .row-styleguide {
    margin: 15px;

    .row,
    .row-error {
      margin-top: 20px;
    }
  }

  .wrap-btn-styleguide {
    float: left;
    margin-right: 10px;

    .btn {
      margin: 10px 10px 10px 0;
    }
  }

  .subsection {
    margin-bottom: 50px;
  }

  .style-guide-color {
    min-height: 80px;
    background: $color-light;
    box-shadow: 0 2px 2.7px 0.3px rgba(25, 25, 25, 0.3);
    margin: 10px 0;
    padding: 10px;

    p {
      font-size: 13px;
    }

    &:hover {
      box-shadow: 0 3px 4px 0.5px rgba(25, 25, 25, 0.3);
    }

    &.style-guide-color1 {
      background: #42a5f5;
    }

    &.style-guide-color2 {
      background: #36a0f7;
    }

    &.style-guide-color3 {
      background: #90a4ae;
    }

    &.style-guide-color4 {
      background: #546e7a;
    }

    &.style-guide-color5 {
      background: #e87366;
    }

    &.style-guide-color6 {
      background: #64b5f6;
    }

    p {
      color: $color-light;
    }
  }

  .page-styleguide .content-inner .select-box.box-appointment-summary {
    padding-bottom: 50px;
  }

  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: block;
  }

  .collapse-row-head {
    padding: 15px 15px 5px 15px;
  }

  .collapsed-row {
    width: 100%;
    padding: 15px 0;

    label,
    select {
      width: 100%;
    }
  }

  .form-medical-info {
    .btn {
      margin-top: 30px;
    }
  }

  /* map width*/
  .map-modal {
    max-width: 80%;
    width: 760px;
  }

  .form-row-border-bottom {
    border-bottom: 1px solid $border-row;
  }

  .account-bottom-row {
    padding-right: 0;
    padding-left: 0;
    margin: 0;

    &.wrap-switch-row .md-switch {
      float: left;
    }
  }

  nav.navbar .wrap-navbar-left {
    padding-left: 50px;

    .nav-link {
      padding: 0 17px;
    }
  }

  .nav-link-active {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: $active-nav-link-line;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .btn-book-appointment {
    float: right;
  }

  .app-left {
    background-color: #fafbfc;
    float: left;
    margin: -10px 0;
    padding: 20px 19px;
    text-align: right;
  }

  .box.box-appointment-account {
    padding: 0 15px;
    margin: 0 0 20px 0;
  }

  .wrap-actions {
    display: flex;
  }

  .action {
    display: flex;
    align-items: center;
    cursor: pointer;

    i,
    span {
      float: left;
      text-align: left;
      color: $actions-color;
    }

    i {
      padding-right: 5px;
    }

    i.status {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      float: left;

      &.status-accepted {
        background-color: $status-accepted;
      }

      &.status-pending {
        background-color: $status-pending;
      }

      &.status-canceled,
      &.status-cancelled {
        background-color: $status-canceled;
      }

      &.status-completed {
        background-color: $status-completed;
      }

      &.status-missed {
        background-color: $status-missed;
      }
    }

    p {
      line-height: 18px;
    }
  }

  .wrap-actions-left {
    float: left;
    display: flex;
    justify-content: flex-start;

    i {
      margin-right: 5px;
    }
  }

  .wrap-actions-right {
    float: right;
    display: flex;
    justify-content: flex-end;

    .action {
      padding-left: 30px;
    }
  }

  .box-appointment-account {
    color: #90a4ae;

    .row {
      padding: 10px 0 10px 0;
      margin-bottom: 20px;

      p {
        margin-bottom: 5px;
      }
    }

    .row-border-bottom {
      border-bottom: 1px solid $border-color;
      margin-bottom: 0;
    }

    .app-right {
      padding-right: 25px;

      .row {
        margin-bottom: 0;
      }

      .row-nowrap {
        flex-wrap: nowrap;
      }
    }

    .action {
      cursor: pointer;
    }

    &.box-appointment-modal {
      background: #fff;
    }

    .action-padding {
      padding: 5px 0;
    }
  }

  .textarea-modal {
    width: 100%;
    height: 140px;
    background-color: #ffffff;
    box-shadow: 0 2px 2.7px 0.3px rgba(25, 25, 25, 0.03);
    border: 0;
  }

  .cancel-modal-title {
    padding-left: 0;
  }

  .inline-title {
    white-space: nowrap;
    padding: 15px;
  }

  .mat-dialog-container {
    width: 660px;
    background-color: $color-light;
  }

  .cancel-app-modal-content {
    padding: 20px 70px;

    .btn-cancel1 {
      height: 44px;
      background: $cancel-btn-color;
      margin-right: 20px;
    }

    .btn-delete {
      height: 44px;
      border-radius: 3px;
      color: #fff;
    }

    .title {
      font-size: 22px;
    }

    .subtitle {
      font-size: 16px;
    }
  }

  .file-upload-preview {
    display: flex;
    justify-content: space-between;

    .pdf-file {
      display: flex;
      align-items: center;

      span {
        margin-left: 5px;
      }
    }

    .preview-img {
      max-width: 300px;
      max-height: 200px;
    }
  }

  .cut-text-250 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 250px;
    white-space: nowrap;
  }

  .app-important {
    font-size: 14px;
    font-weight: 500;
    color: #546e7a;
    width: 100%;
    display: block;
  }

  .btn.disabled,
  .btn:disabled,
  .btn-primary.disabled,
  .btn-primary:disabled {
    opacity: 0.5;
  }

  .top-line {
    margin-top: 0;
    width: 40px;
    height: 20px;
    float: left;
    border-bottom: 2px solid #e0e0e0;
  }

  .mat-dialog-container {
    position: relative;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .toast-container .ngx-toastr {
    font-size: 14px;
    letter-spacing: normal;
    text-align: left;
    color: #546e7a;
    background: #fff;
    border-top: 5px solid $color-primary;

    &:before {
      content: 'info';
      font-family: $font-icons-material;
      float: left;
      font-size: 30px;
      line-height: 40px;
      position: absolute;
      top: 5px;
      left: 15px;
      color: $color-primary;
    }

    &.toast-success {
      border-top: 5px solid $color-sucess1;

      &:before {
        content: 'check_circle';
        color: $color-sucess1;
      }
    }

    &.toast-error {
      border-top: 5px solid $color-error;

      &:before {
        content: 'error';
        color: $color-error;
      }
    }

    &.toast-warning {
      border-top: 5px solid $color-warning;

      &:before {
        content: 'warning';
        color: $color-warning;
      }
    }

    &.toast-success {
      border-top: 5px solid $color-sucess1;

      &:before {
        content: 'check_circle';
        color: $color-sucess1;
      }
    }
  }

  .button.toast-close-button {
    background-color: #95a5b7;
  }

  input {
    &.ng-invalid {
      &.ng-touched {
        background-image: linear-gradient($color-error, $color-error), linear-gradient($color-error, $color-error);
      }
    }
  }

  .wrap-actions-upcoming-app {
    padding: 15px 25px;
    background-color: #fafbfc;
    width: 100%;
    float: left;
    max-width: 100%;
    flex: 0 0 100%;

    .action {
      padding-right: 25px;
    }
  }

  .wrap-actions-apt-page {
    padding: 15px 25px;
    background-color: #fafbfc;
    float: left;
    flex: 0 0 100%;

    .action {
      padding-right: 25px;
    }
  }

  .onboarding-container-page {
    .subtitle-create-account {
      margin-bottom: 15px;
    }

    .wrap-gender-sel,
    .wrap-sel {
      .mat-select-value,
      .mat-select {
        margin-left: 0;
        margin-right: 0;
      }
    }

    // .form-row {
    //     padding: 10px 15px 0 15px;
    // }
  }

  .wrap-medical-info {
    .wrap-switch-row {
      padding-bottom: 0;
    }

    .onboarding-add-link {
      color: $actions-color;
      padding: 15px 0 5px 0;
      float: left;

      .edit-btn {
        float: left;
        padding: 0 5px 0 0;
      }

      span {
        float: left;
        line-height: 26px;
      }
    }

    .form-row {
      padding: 10px 15px;
    }
  }

  .title-collapse {
    text-decoration: none;
    color: $font-color;
    font-weight: 500;
    width: calc(100% + 60px);
    float: left;
    cursor: pointer;
    background: #f1f3f6;
    padding: 15px 30px;
    margin: -15px -30px;

    &:hover {
      text-decoration: none;
    }
  }

  .signature-form {
    margin-left: -10px;
  }

  .wrap-pp-date {
    padding: 10px 25px;
  }

  .content-inner .select-clinic-box .grid-stack-item-content {
    // border: 1px solid $border-color;
    // padding: 15px;
    .md-radio label {
      padding: 0 25px;
    }
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $color-primary;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $color-primary;
  }

  .mat-accent .mat-pseudo-checkbox-checked,
  .mat-accent .mat-pseudo-checkbox-indeterminate,
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background-color: $color-primary;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $color-primary;
  }

  //remove white-space from label
  .mat-checkbox-label {
    white-space: normal;
  }

  .mat-accent .mat-slider-thumb,
  .mat-accent .mat-slider-thumb-label,
  .mat-accent .mat-slider-track-fill {
    background-color: $color-primary;
  }

  .mat-accent .mat-ripple-element {
    background-color: rgba($color-primary, 0.26);
  }

  .mat-accent .mat-ripple-element,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element,
  .mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element,
  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    background-color: rgba($color-primary, 0.26);
  }

  .top-subtitle-appointment-loggedin {
    padding: 10px 0 10px 0;
  }

  .row-app-right {
    label {
      font-size: 14px;
      color: $label-color;
      float: left;
      width: 100%;
    }

    p {
      font-size: 14px;
      color: $font-color;
      float: left;
    }

    .payment-check-icon {
      img {
        width: 30px;
        float: left;
        padding-right: 10px;
      }
    }

    .payment-mastercard-icon {
      img {
        width: 30px;
        float: left;
      }
    }

    .wrap-payment-icon,
    .wrap-payment-name {
      display: flex;
      align-items: center;
      margin: 0 0 10px 0;
      padding-right: 0;
    }

    .payment-icon {
      width: 30px;
      float: left;

      img {
        width: 100%;
        float: left;
      }
    }

    i {
      float: left;
    }

    span {
      line-height: 24px;
      float: left;
    }
  }

  .subtitle-app-right {
    font-size: 16px;
    font-weight: 500;
  }

  .mat-subtitle .mat-select-value {
    font-size: 17px;
  }

  pp-form-render {
    width: 100%;
  }

  pp-date-input,
  pp-gender-input {
    input.form-control-select,
    .form-control.form-control-select,
    .form-control.form-control-select.form-render {
      //min-width: 50px;
      margin: 0;
    }
  }

  .form-control.form-control-select.form-render {
    min-width: 50px;
    margin: 0;
  }

  .mat-tooltip {
    max-width: 100% !important;
    font-size: 14px;
  }

  .insurance-image {
    max-width: 300px;
    max-height: 200px;
  }
}

@media (min-width: 991px) {
  .onboarding-container-page {
    .grid-stack {
      pp-date-input {
        float: left;
        min-width: 300px;

        .form-control.form-control-select {
          min-width: 45px;
          margin-right: 20px;
        }
      }

      // &.grid-stack-4 .grid-stack-item[data-gs-x='2'] {
      //   //Particular text-field css issue (FLORIN)
      //   width: 50%;
      //   float: left;
      // }
      &.grid-stack-4 .grid-stack-item[data-gs-width='4'] {
        //Medical forms text and yes/no controls do not align (FLORIN)
        width: calc(100% - 15px);
      }
    }
  }
}

/* ==========================================================================
	Media queries
========================================================================== */

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

/* Large devices (≥992px) */

@media (max-width: 1199px) {
  nav.navbar .nav-link {
    //padding-left: 20px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }
}

@media (max-width: 991px) {
  .content-inner .select-clinic-box .md-radio label {
    max-height: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .payment-md {
    display: none;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100% !important;
  }

  .xs-status-left {
    float: left;
  }

  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .top-title {
    padding: 0 15px;
  }

  .content-inner .box.login-box {
    margin: 20px 0;
  }

  .box.login-box .subtitle {
    font-size: 18px;
    letter-spacing: -0.3px;
  }

  nav.navbar {
    .navbar-brand {
      max-width: 50%;
    }

    .menu-btn.active span:nth-of-type(2) {
      transform: rotateY(180deg);
    }

    .navbar-container {
      padding: 0 20px;
    }
  }

  .content-inner {
    width: calc(100% - 40px);
  }

  .content-inner.active {
    width: calc(100% - 90px);
  }

  .content-inner .select-clinic-box .view-map-link {
    padding-left: 45px;
    display: block;
  }

  nav.navbar .nav-link {
    padding: 0 0 0 10px;
  }

  .content-inner .select-clinic-box .md-radio label {
    margin: 0;
    width: 100%;
    padding: 0 0 0 35px;
  }

  .content-inner .select-clinic-box {
    padding: 20px 30px 100px 30px;
  }

  .navbar-brand {
    margin-right: 10px;
  }

  .head-calendar {
    .wrap-navigation {
      float: right;
      width: auto;

      .nav-link {
        &.next {
          padding: 0 0 0 8px;
        }

        span {
          font-size: 0;
        }
      }
    }
  }

  .content-inner .select-clinic-box .details-link {
    margin: 15px 0;
  }

  .btn.btn-large-mobile {
    width: 500px;
    max-width: 100%;
  }

  /*.row-edit:last-child {
	  margin: 0 -15px;
		.edit-col{
			padding: 15px 15px 5px 15px;
		}
	}*/
  /*this caused problems, not sure why was used*/
  .row-edit.row-edit-actions .edit-col {
    padding: 20px 10px;
  }

  .wrap-select {
    margin-right: 0;
    margin-left: 0;
  }

  .content-inner {
    .select-box {
      .row-md-radio:nth-child(2n) {
        background-color: #fafbfc;
        margin-right: -15px;
        margin-left: -15px;
        padding: 0;
      }
    }

    .md-radio label strong,
    .md-checkbox label strong {
      word-wrap: break-word;
    }
  }

  .map-modal {
    //width: 60%;
    width: 80%;
    border-radius: 0;
    max-width: 80% !important;
    left: 0 !important;
  }

  .row-edit {
    margin-right: -15px;
    margin-left: -15px;
  }

  .wrap-map-app {
    margin: 0 -15px;
  }

  //action icons padding
  .wrap-actions-right .action {
    padding-left: 5px;
  }
}

.row-edit.row-edit-actions .edit-col {
  padding: 20px 10px;
}

.wrap-select {
  margin-right: 0;
  margin-left: 0;
}

.content-inner {
  .select-box {
    .row-md-radio:nth-child(2n) {
      background-color: #fafbfc;
      margin-right: -15px;
      margin-left: -15px;
      padding: 0;
    }
  }

  .md-radio label strong,
  .md-checkbox label strong {
    word-wrap: break-word;
  }
}

.map-modal {
  //width: 60%;
  width: 80%;
  border-radius: 0;
  max-width: 80% !important;
  left: 0 !important;
}

.row-edit {
  margin-right: -15px;
  margin-left: -15px;
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 576px) {
  nav.navbar {
    font-size: 14px;
  }

  nav.navbar .badge {
    width: 19px;
    height: 19px;
  }

  nav.navbar .nav-item > a {
    font-size: 13px;
  }

  nav.navbar .dropdown-menu {
    right: auto;
    left: -50%;
    transform: translateX(-50%);
  }

  .content-inner .select-clinic-box {
    padding: 20px 15px 30px 15px;
  }

  .content-inner .select-box {
    padding: 20px 15px 30px 15px;
  }

  .cancel-app-modal-content {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0;
    height: 100%;
  }
}

@media print {
  *,
  *:before,
  *:after {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }
}

.not-found-section {
  margin: 0px;
}

// prevent zoom in on input on mobile
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

.mat-select-placeholder {
  color: #00000073;
}

.mat-select-value {
  color: #546e7a;
}

.mat-option {
  color: #546e7a;
}
.edit-entry {
  padding: 15px 15px 5px 15px;
  text-align: right;
}
#telehealth-bar {
  background-color: #28afa5;
  text-align: center;
  vertical-align: middle;
  position: relative;
}

.telehealth-link {
  color: #ffffff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 48px;
}
#telehealth-text {
  color: #ffffff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 48px;
}

.form-invalid-border {
  border: 1px red solid !important;
  border-radius: 5px;
}

.nowrap {
  white-space: nowrap;
}
.page-content {
  display: flex;
}

.word-break-all {
  word-break: break-word;
}

.pp-container {
  padding-right: 1rem !important;
}

@media (max-width: 1280px) {
  .pp-container {
    padding-left: 1rem !important;
  }
}

#fullsteam-submit-button {
  color: #fff;
  background-color: #36a0f7;
  border: none;
  outline: 0;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  letter-spacing: normal;
  min-width: 96px;
  height: 44px;
  border-radius: 3px;
  background-image: none;
}

#fullsteam-hosted-card-number-div,
#fullsteam-hosted-expiration-month-frame,
#fullsteam-hosted-expiration-year-frame,
#fullsteam-hosted-cvv-frame {
  height: 30px !important;
}
#fullsteam-hosted-expiration-month-frame,
#fullsteam-hosted-expiration-year-frame {
  padding-right: 20px;
}

#fullsteam-hosted-cvv-frame {
  border-bottom: 1.5px solid #ddd !important;
}

.cursor-pointer {
  cursor: pointer;
}

.m-0-auto {
  margin: 0 auto;
}

.modal-error .mat-dialog-container {
  background: #FFF3F0;
}
.str-chat__avatar-fallback {
  padding-top: 0 !important;
}
.starter-file-upload-button span.str-chat__input-flat-fileupload:after {
  padding-top: 2px !important;
}
