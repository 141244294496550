$gridstack-columns: 12 !default;
$horizontal_padding: 20px !default;
$vertical_padding: 20px !default;

@mixin vendor($property, $value...) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
  #{$property}: $value;
}

@mixin grid-stack-items($gridstack-columns) {
  @media (min-width: 991px) {
    .grid-stack.grid-stack-#{$gridstack-columns} {
      .grid-stack-item {
        min-width: 100% / $gridstack-columns;

        @for $i from 1 through $gridstack-columns {
          &[data-gs-width='#{$i}'] {
            width: (100% / $gridstack-columns) * $i;
          }

          &[data-gs-x='#{$i}'] {
            left: (100% / $gridstack-columns) * $i;
          }

          &[data-gs-min-width='#{$i}'] {
            min-width: (100% / $gridstack-columns) * $i;
          }

          &[data-gs-max-width='#{$i}'] {
            max-width: (100% / $gridstack-columns) * $i;
          }
        }
      }
    }
  }
}

@for $j from 1 through $gridstack-columns {
  @include grid-stack-items($j)
}

.grid-stack {
  position: relative;

  .grid-stack-item {
    min-width: 100% / $gridstack-columns;
    position: absolute;
    padding: 0;

    >.grid-stack-item-content {
      margin: 0;
      position: absolute;
      top: 0;
      left: $horizontal_padding / 2;
      right: $horizontal_padding / 2;
      bottom: 0;
      width: auto;
      z-index: 0 !important;
      overflow: hidden;
    }

    .md-checkbox,
    .md-radio {
      margin: 5px 0;
      padding: 0;
    }

    .yes-checkbox {
      margin-right: 10px;
    }
  }
}

.grid-stack.grid-stack-one-column-mode {
  height: auto !important;

  .grid-stack-item {
    position: relative !important;
    width: auto !important;
    left: 0 !important;
    top: auto !important;
    margin-bottom: $vertical_padding;
    max-width: none !important;
    height: auto !important;

    >.grid-stack-item-content {
      position: relative !important;
      width: auto !important;
      left: 0 !important;
      top: auto !important;
      margin-bottom: $vertical_padding;
      max-width: none !important;
      height: auto !important;
    }
  }
}

signature-pad {
  canvas {
    border: 1px solid #eceff1;
    border-radius: 3px;
  }
}

.selected {
  border-left: 4px solid #81c884;
  background-color: rgba(193, 204, 209, 0.2);
}

.m-selected {
  border: 2px solid #81c884;
  background-color: #81c884;
  padding: 3px;
  border-radius: 20px;
  margin: 10px;
  font-weight: bold;
  font-family: sans-serif;
  color: white !important;
  white-space: pre;
}

.m-not-selected {
  border: 2px solid #546e7a;
  padding: 3px;
  border-radius: 20px;
  margin: 10px;
  font-weight: bold;
  font-family: sans-serif;
  white-space: pre;
}

.m-form-nav {
  padding: 10px;
}

.m-subtitle {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  line-height: 30px;
  color: #263238;
  margin-bottom: 15px;
}

.m-box {
  background: #fff;
  box-shadow: 0 2px 2.7px 0.3px rgba(25, 25, 25, 0.03);
  padding: 25px 20px;
  min-height: 100px;
  border: 0;
  border-radius: 0;
  margin-bottom: 25px;
}

.m-icon-action {
  line-height: 2;
  vertical-align: middle;
  font-size: 40px;
  color: #42a5f5;
  cursor: pointer;
}

.onboarding-dialog {
  .btn-default {
    color: #546e7a;
    border: 1px solid #e4e4e4;
    background-color: #e4e4e4;

    &:hover {
      background-color: #fff;
      color: #42a5f5;
      border-color: #42a5f5;
    }

    &:active {
      background-color: #42a5f5;
      color: #fff;
      border-color: #42a5f5;
    }
  }



}
