@import '~styles/variables';

.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: $color-primary;
  .mat-icon {
    color: $color-primary;
    stroke: $color-primary;
    fill: $color-primary;
  }
}
.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {
  background: $color-primary;
  border-radius: 5px;
}

.mat-button:hover {
  color: $color-button-hover;
  .mat-icon {
    color: $color-button-hover;
    stroke: $color-button-hover;
    fill: $color-button-hover;
  }
  .mat-button-focus-overlay {
    background: transparent;
  }
}
